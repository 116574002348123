import React from "react";
import { Nav } from "./navbar.js";
import { useTranslation } from "react-i18next";

export default function Hero() {
  const { t } = useTranslation();
  return (
    <div class="bg-slate-900">
      <div class="bg-gradient-to-b from-violet-600/[.15] via-transparent">
        <Nav />
        <div class="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 py-24 space-y-8">
          <div class="max-w-3xl text-center mx-auto">
            <h1 class="block ext-3xl lg:text-7xl font-bold text-gray-200 text-4xl sm:text-5xl md:text-6xl ">
              {t("promo.title")}
            </h1>
          </div>

          <div class="max-w-3xl text-center mx-auto">
            <p class="text-2xl text-slate-100">
            {t("promo.subtitle")}{" "}
              <span class="underline">{t("promo.subtitle2")}{" "}</span>{t("promo.subtitle3")}
            </p>
            
          </div>
          { /* Whatssapp Button Message */}
          
        </div>
      </div>
    </div>
  );
}

