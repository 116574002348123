import { useState as _useState$ } from "react";
import { renderReactScope as _renderReactScope$ } from "million/react";
import { block as _block$ } from "million/react";
//search.tsx

import React, { useState } from "react";
import { Search, MapPin, Briefcase, ChevronDown } from "lucide-react";
import "../../../translations/i18n";
import { useTranslation } from "react-i18next";
const M$2 = ({
  handleFilter
}) => {
  const [locationOpen, setLocationOpen] = useState(false);
  const [jobTypeOpen, setJobTypeOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedJobType, setSelectedJobType] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const {
    t
  } = useTranslation();
  const Locations = ["Heredia", "San José", "Remote"];
  const JobTypes = ["Full Time", "Part Time", "Temporary", "Commission", "Per Hour"];
  const handleSearch = () => {
    console.log("Search for:", searchInput, selectedLocation, selectedJobType);
    // Aquí puedes implementar la lógica de búsqueda
    handleFilter(selectedLocation, selectedJobType, searchInput);
  };
  const closeLocationDropdown = () => {
    setLocationOpen(false);
  };
  const closeJobTypeDropdown = () => {
    setJobTypeOpen(false);
  };

  // close dropdowns when clicking outside
  document.addEventListener("click", e => {
    if ((e.target as Element) && !(e.target as Element).closest(".relative")) {
      closeLocationDropdown();
      closeJobTypeDropdown();
    }
  });
  const _portal = _useState$(() => ({
      $: new Array(9)
    }))[0],
    _ = _renderReactScope$(<Search className="text-gray-500 dark:text-gray-400" />, false, _portal.$, 0, false),
    _2 = t("jobs.searchPlaceholder"),
    _3 = e => setSearchInput(e.target.value),
    _4 = () => setSearchInput(''),
    _5 = _renderReactScope$(<MapPin className="text-gray-500 dark:text-gray-400" />, false, _portal.$, 1, false),
    _6 = () => {
      setLocationOpen(!locationOpen);
      closeJobTypeDropdown();
    },
    _7 = _renderReactScope$(selectedLocation || t("jobs.location"), false, _portal.$, 2, false),
    _8 = _renderReactScope$(<ChevronDown className="ml-1 dark:text-gray-400" />, false, _portal.$, 3, false),
    _9 = _renderReactScope$(locationOpen && <div className="origin-top-left absolute left-0 mt-10 md:mt-0 rounded-md shadow-lg bg-white z-10 dark:bg-gray-900">
                  {Locations.map(location => <div key={location} className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-left dark:text-gray-100 dark:hover:bg-gray-700" onClick={() => {
        setSelectedLocation(location);
        closeLocationDropdown();
      }}>
                      {location}
                    </div>)}
                </div>, false, _portal.$, 4, false),
    _10 = _renderReactScope$(<Briefcase className="text-gray-500 dark:text-gray-400" />, false, _portal.$, 5, false),
    _11 = () => {
      setJobTypeOpen(!jobTypeOpen);
      closeLocationDropdown();
    },
    _12 = _renderReactScope$(selectedJobType || t("jobs.jobType"), false, _portal.$, 6, false),
    _13 = _renderReactScope$(<ChevronDown className="ml-1 dark:text-gray-400" />, false, _portal.$, 7, false),
    _14 = _renderReactScope$(jobTypeOpen && <div className="origin-top-left absolute left-0 mt-10 md:mt-0 rounded-md shadow-lg bg-white z-10 dark:bg-gray-900">
                  {JobTypes.map(jobType => <div key={jobType} className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-left dark:text-gray-100 dark:hover:bg-gray-700" onClick={() => {
        setSelectedJobType(jobType);
        closeJobTypeDropdown();
      }}>
                      {jobType}
                    </div>)}
                </div>, false, _portal.$, 8, false),
    _15 = t("jobs.searchButton");
  return <><P$ _={_} _2={_2} searchInput={searchInput} _3={_3} _4={_4} _5={_5} _6={_6} _7={_7} _8={_8} _9={_9} _10={_10} _11={_11} _12={_12} _13={_13} _14={_14} handleSearch={handleSearch} _15={_15} />{_portal.$.map(p => p.portal)}</>;
};
const P$ = /*million:transform*/_block$(({
  _,
  _2,
  searchInput,
  _3,
  _4,
  _5,
  _6,
  _7,
  _8,
  _9,
  _10,
  _11,
  _12,
  _13,
  _14,
  handleSearch,
  _15
}) => {
  return <div className="flex flex-col md:flex-row md:items-center py-4 px-4">
      <div className="mx-auto px-4 py-4">
        <div className="flex flex-col md:flex-row md:items-center shadow-lg rounded-xl md:border-t dark:border-gray-700 dark:bg-gray-900">
          <div className="relative flex items-center pl-4 space-x-2 px-4 py-5  md:border-r md:w-2/5">
            {_}
            <input className="w-full py-1 focus:outline-none dark:bg-gray-900 dark:text-gray-100" type="text" placeholder={_2} value={searchInput} onChange={_3} onClick={_4} />
          </div>
          <div className="relative flex items-center pl-4 space-x-2 border-l mb-5 md:mb-0 md:border-l-0 md:w-1/5">
            {_5}
            <div className="flex items-center">
              <button className="flex items-center text-gray-700 hover:text-black focus:outline-none dark:text-gray-400 dark:hover:text-gray-100" onClick={_6}>
                {_7}
                {_8}
              </button>
              {_9}
            </div>
          </div>
          <div className="relative flex items-center pl-4 space-x-2 border-l mb-5 md:mb-0 md:border-l-0 md:w-1/5">
            {_10}
            <div className="flex items-center">
              <button className="flex items-center text-gray-700 hover:text-black focus:outline-none dark:text-gray-400 dark:hover:text-gray-100" onClick={_11}>
                {_12}
                {_13}
              </button>
              {_14}
            </div>
          </div>
          <button className="flex items-center justify-center px-8 text-white mb-3 sm:mb-0 bg-blue-600 hover:bg-blue-700 rounded-lg ml-4 mr-2 py-2 px-4 md:w-1/5" onClick={handleSearch}>
            {_15}
          </button>
        </div>
      </div>
    </div>;
}, {
  svg: false,
  shouldUpdate: (a, b) => a?._ !== b?._ || a?._2 !== b?._2 || a?.searchInput !== b?.searchInput || a?._3 !== b?._3 || a?._4 !== b?._4 || a?._5 !== b?._5 || a?._6 !== b?._6 || a?._7 !== b?._7 || a?._8 !== b?._8 || a?._9 !== b?._9 || a?._10 !== b?._10 || a?._11 !== b?._11 || a?._12 !== b?._12 || a?._13 !== b?._13 || a?._14 !== b?._14 || a?.handleSearch !== b?.handleSearch || a?._15 !== b?._15
});
const SearchBar = M$2;
export default SearchBar;