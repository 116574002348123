import React, { useState, useRef } from "react";
import "../../../translations/i18n";
import { useTranslation } from "react-i18next";
export default function Cards({ jobOffers }) {
  const { t } = useTranslation();

  const [showJobDialog, setShowJobDialog] = useState(false);
  const [showCvDialog, setShowCvDialog] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [cvSent, setCvSent] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [formData, setFormData] = useState({
    email: "",
    fullName: "",
    phoneNumber: "",
    jobTitle: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Nuevo estado para controlar el loader

  const openJobDialog = (job) => {
    setSelectedJob(job);
    setShowJobDialog(true);
  };

  const closeJobDialog = () => {
    setSelectedJob(null);
    setShowJobDialog(false);
    setCvSent(false);
    setSelectedFile(null);
    setErrorMessage("");
    setFormData({
      email: "",
      fullName: "",
      phoneNumber: "",
      jobTitle: "",
    });
  };

  const openCvDialog = () => {
    setShowJobDialog(false);
    setShowCvDialog(true);
  };

  const closeCvDialog = () => {
    setShowCvDialog(false);
    setShowJobDialog(true);
    setCvSent(false);
    setSelectedFile(null);
    setErrorMessage("");
    setFormData({
      email: "",
      fullName: "",
      phoneNumber: "",
      jobTitle: selectedJob ? selectedJob.title : "",
    });
  };

  const handleFileSelect = (e) => {
    setSelectedFile(e.target.files[0]);
    setErrorMessage("");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrorMessage("");
  };

  const handleCvSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Mostrar el loader

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("email", formData.email);
      formDataToSend.append("fullName", formData.fullName);
      formDataToSend.append("phoneNumber", formData.phoneNumber);
      formDataToSend.append("cv", selectedFile);
      formDataToSend.append("jobTitle", selectedJob.title);

      const response = await fetch("http://localhost:3002/api/upload-cv", {
        method: "POST",
        body: formDataToSend,
      });

      if (response.ok) {
        setCvSent(true);
        setErrorMessage("");

        setTimeout(() => {
          setCvSent(false);
          setSelectedFile(null);
          setFormData({
            email: "",
            fullName: "",
            phoneNumber: "",
            jobTitle: selectedJob ? selectedJob.title : "",
          });
          setShowJobDialog(false);
          setShowCvDialog(false);
        }, 4000);
      } else {
        throw new Error(t("SubmitErrorMessage.text"));
      }
    } catch (error) {
      console.error("Error CV:", error);
      setErrorMessage(t("SubmitErrorMessage.text"));
    } finally {
      setIsLoading(false); // Ocultar el loader después de recibir la respuesta
    }
  };

  const jobDialogRef = useRef(null);
  const cvDialogRef = useRef(null);

  const handleClickOutside = (e) => {
    if (jobDialogRef.current && !jobDialogRef.current.contains(e.target)) {
      closeJobDialog();
    }
    if (cvDialogRef.current && !cvDialogRef.current.contains(e.target)) {
      closeCvDialog();
    }
  };

  return (
    <div id="jobs" className="grid grid-cols-1 gap-8 px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 ">
      <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
        {jobOffers.map((jobOffer, index) => (
          <div
            key={index}
            className="p-8 transition-shadow duration-300 bg-white border border-gray-200 rounded shadow-sm hover:shadow-xl relative dark:bg-gray-900"
          >
            <h6 className="mb-4 text-lg font-semibold leading-5 dark:text-gray-100">
              {jobOffer.title}
            </h6>
            <p className="mb-2 text-gray-700 dark:text-gray-300">
              {jobOffer.description}</p>
            <div className="flex items-center justify-between mb-10">
              <p className="text-sm font-medium text-blue-800">
                {t("jobs.location")}{" "}
                <span className="text-gray-600 dark:text-gray-400">{jobOffer.location}</span>
              </p>
              <p className="text-sm font-medium text-blue-800">
              {t("jobs.jobType")}:{" "}
                <span className="text-gray-600 dark:text-gray-400">{jobOffer.jobType}</span>
              </p>
            </div>
            <p className="text-sm font-medium text-gray-400 absolute top-2 right-2 dark:text-gray-400">
              {jobOffer.datePosted}
            </p>
            <button
              onClick={() => openJobDialog(jobOffer)}
              className="absolute mt-10 bottom-4 right-4 px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none"
            >
              {t("jobs.viewButton")}
            </button>
          </div>
        ))}
      </div>
      {showJobDialog && (
        <div
          className="fixed inset-0 z-50 overflow-y-auto bg-gray-800 bg-opacity-75 flex items-center justify-center"
          onClick={handleClickOutside}
        >
          <div
            ref={jobDialogRef}
            className="relative p-8 bg-white rounded-lg shadow-lg max-w-3xl w-full dark:bg-gray-700"
          >
            <button
              onClick={closeJobDialog}
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 dark:text-gray-400"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <h5 className="mb-4 text-2xl text-center underline font-semibold leading-5 dark:text-gray-100">
              {selectedJob.title}
            </h5>
            <h3 className="mb-2 font-bold text-xl text-gray-700 dark:text-gray-100">
              {t("jobs.jobDescription")}:{" "}
            </h3>
            <p className="mb-2 text-gray-700 dark:text-gray-300"> {selectedJob.fullDescription}</p>
            <div className="flex items-center justify-between">
              <p className="text-sm font-medium text-blue-800">
              {t("jobs.location")}:{" "}
                <span className="text-gray-600 dark:text-gray-300">{selectedJob.location}</span>
              </p>
              <p className="text-sm font-medium text-blue-800 ">
              {t("jobs.location")}:{" "}
                <span className="text-gray-600 dark:text-gray-300">{selectedJob.jobType}</span>
              </p>
            </div>
            <p className="text-sm font-medium text-blue-800">
            {t("jobs.datePosted")}:{" "}
              <span className="text-gray-600 dark:text-gray-300">{selectedJob.datePosted}</span>
            </p>
            <p className="text-sm font-medium text-blue-800">
            {t("jobs.salary")}:{" "}
              <span className="text-gray-600 dark:text-gray-300">{selectedJob.salary}</span>
            </p>
            <h3 className="mt-4 font-bold text-xl text-gray-700 dark:text-gray-100">
            {t("jobs.jobRequirements")}
            </h3>
            <ul className="list-disc list-inside">
              {selectedJob.requirements.map((requirement, index) => (
                <li key={index} className="text-gray-700 dark:text-gray-300">
                  {requirement}
                </li>
              ))}
            </ul>
            <div className="flex flex-col mt-4">
              <button
                onClick={openCvDialog}
                className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 focus:outline-none"
              >
                {t("jobs.applyButton")}
              </button>
            </div>
          </div>
        </div>
      )}
      {showCvDialog && (
        <div
          className="fixed inset-0 z-50 overflow-y-auto bg-gray-800 bg-opacity-75 flex items-center justify-center"
          onClick={handleClickOutside}
        >
          <div
            ref={cvDialogRef}
            className="relative p-8 bg-white rounded-lg shadow-lg max-w-3xl w-full dark:bg-gray-700"
          >
            <button
              onClick={closeCvDialog}
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 dark:text-gray-400"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <h5 className="mb-4 text-2xl text-center underline font-semibold leading-5 dark:text-gray-100">
              {selectedJob.title}
            </h5>
            <form onSubmit={handleCvSubmit}>
              <div className="flex flex-col">
                <label
                  htmlFor="fullName"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-100"
                >
                  {t("jobs.fullName")}
                </label>
                <input
                  id="fullName"
                  type="text"
                  name="fullName"
                  value={formData.fullName}
                  placeholder="Your full name"
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full px-4 py-2 rounded-md bg-gray-100 border border-gray-300 focus:outline-none focus:border-blue-500 focus:bg-white dark:bg-gray-900 dark:border-gray-700 dark:text-gray-300"
                />
              </div>
              <div className="flex flex-col mt-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-100"
                >
                  {t("jobs.email")}
                </label>
                <input
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Your email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full px-4 py-2 rounded-md bg-gray-100 border border-gray-300 focus:outline-none focus:border-blue-500 focus:bg-white dark:bg-gray-900 dark:border-gray-700 dark:text-gray-300"
                />
              </div>
              <div className="flex flex-col mt-4">
                <label
                  htmlFor="phoneNumber"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-100"
                >
                  {t("jobs.phoneNumber")}
                </label>
                <input
                  id="phoneNumber"
                  type="tel"
                  name="phoneNumber"
                  placeholder="Phone number (+506)"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full px-4 py-2 rounded-md bg-gray-100 border border-gray-300 focus:outline-none focus:border-blue-500 focus:bg-white dark:bg-gray-900 dark:border-gray-700 dark:text-gray-300"
                />
              </div>
              <div className="flex flex-col mt-4">
                <label
                  htmlFor="cvUpload"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-100"
                >
                  {t("jobs.uploadCV")}
                </label>
                <div className="relative">
                  <input
                    id="cvUpload"
                    type="file"
                    accept=".pdf"
                    onChange={handleFileSelect}
                    className="mt-1 block w-full px-4 py-2 rounded-md bg-gray-100 border border-gray-300 focus:outline-none focus:border-blue-500 focus:bg-white dark:bg-gray-900 dark:border-gray-700 dark:text-gray-300"
                  />
                  {selectedFile && (
                    <span className="absolute inset-y-0 right-0 flex items-center pr-3 text-sm text-gray-400">
                      {selectedFile.name}
                    </span>
                  )}
                </div>
              </div>
              {errorMessage && (
                <p className="text-sm text-red-600">{errorMessage}</p>
              )}
              <button
                type="submit"
                className="mt-4 bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 focus:outline-none"
              >
                {t("jobs.sendApplication")}
              </button>
              {cvSent && (
                <p className="mt-2 text-green-600">
                  {t("jobs.applicationSuccess")}
                </p>
              )}
              {isLoading && (
                <div className="fixed inset-0 flex items-center justify-center">
                  <div className="animate-spin rounded-full h-20 w-20 border-t-2 border-b-2 border-blue-500"></div>
                </div>
              )}
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
